/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Ashutosh's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable, scalable and technical systems to create impact.",
  og: {
    title: "Ashutosh Kumar Portfolio",
    type: "website",
    url: "https://ashutoshkumar.live/",
  },
};

//Home Page
const greeting = {
  title: "Ashutosh Kumar",
  logo_name: "AshutoshKumar",
  nickname: "tech_enthusiast",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable, scalable and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1TvxPsQbK2WEII5J1UpBsPM8QT8oquEB5/view?usp=drive_link",
  portfolio_repository: "https://github.com/kashutoshswe/kashutoshswe.github.io",
  githubProfile: "https://github.com/kashutoshswe",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/kashutoshswe",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/ashutoshkumarlink/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:mailtoashutoshkumars@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Application Development",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready applications",
        "⚡ Experience of working with Spring Secutiy and Microservices Architecture",
        "⚡ Complex quantitative modelling for real time dashboard and data analysis",
      ],
      softwareSkills: [
        {
          skillName: "Spring Boot",
          fontAwesomeClassname: "simple-icons:springboot",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Spring",
          fontAwesomeClassname: "simple-icons:spring",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "Spring Security",
          fontAwesomeClassname: "simple-icons:springsecurity",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "API Gateway",
          fontAwesomeClassname: "simple-icons:amazonapigateway",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Spark",
          fontAwesomeClassname: "simple-icons:apachespark",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Open AI",
          fontAwesomeClassname: "simple-icons:openai",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Web Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React",
        "⚡ Developing web applications using Drupal, Wordpress, React and solo android apps using Java",
        "⚡ Creating application backend in Spring Boot, Java",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Drupal",
          fontAwesomeClassname: "simple-icons:drupal",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying real time application dashboards on cloud to use on mobile devices",
        "⚡ Multiple services integrated into applications from GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Jenkins",
          fontAwesomeClassname: "simple-icons:jenkins",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Orcale",
          fontAwesomeClassname: "simple-icons:oracle",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "Tools & Technologies",
      fileName: "DesignImg",
      skills: [
        "⚡ Using effecttive tools for developing mobile and web applications",
        "⚡ Customizing building tools for enhanced productivity",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Intellij Idea",
          fontAwesomeClassname: "simple-icons:intellijidea",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Postman",
          fontAwesomeClassname: "simple-icons:postman",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Github",
          fontAwesomeClassname: "simple-icons:github",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "PyCharm",
          fontAwesomeClassname: "simple-icons:pycharm",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "WakaTime",
          fontAwesomeClassname: "simple-icons:wakatime",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "GeeksforGeeks",
      iconifyClassname: "simple-icons:geeksforgeeks",
      style: {
        color: "#F79F1B",
      },
      profileLink: "",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "MSc in Computer Applications",
      subtitle: "Symbiosis International (Deemed) University, Pune",
      logo_path: "siu2.jpeg",
      alt_name: "Symbiosis Pune",
      duration: "2019 - 2021",
      descriptions: [
        "⚡ Gold Medalist. I have studied Software Architectures, Data Mining, NoSQL, Data Warehousing etc.",
        "⚡ Apart from this, I was the Technical Team Lead & Chief Editor for ACM SICSR Student branch",
        "⚡ I was selected for Merit cum Means Scholarship. I was also a part of research methodology related to OS.",
      ],
      website_link: "https://www.sicsr.ac.in/",
    },
    {
      title: "Bachelor in Computer Applications",
      subtitle: "Symbiosis International (Deemed) University, Pune",
      logo_path: "siu2.jpeg",
      alt_name: "Symbiosis Pune",
      duration: "2016 - 2019",
      descriptions: [
        "⚡ Gold Medalist. I have taken varity of courses DE, Networking, Statistics, DBMS, OS, Computer Architectures etc.",
        "⚡ I was the Chairman of IEEE Student branch of SICSR, which won Best IEEE Student branch Pune & India 2019",
        "⚡ During my time at university, I was also associated with teaching. I conducted lectures and training for gNunify.",
      ],
      website_link: "https://www.sicsr.ac.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Master Microservices with Spring Boot and Spring Cloud",
      subtitle: "- Udemy",
      logo_path: "logo-udemy.svg",
      certificate_link:
        "https://www.udemy.com/certificate/UC-6f8bdc89-eb52-474c-ba56-821dfba6ba76/",
      alt_name: "Udemy",
      color_code: "#1F70C199",
    },
    {
      title: "AWS Certified Developer – Associate",
      subtitle: "- AWS",
      logo_path: "amazon_web_services_logo.jpeg",
      certificate_link:
        "https://www.credly.com/badges/7f8f4234-1ff3-48f4-9663-0f2b21944c15/public_url",
      alt_name: "AWS Developer Associate",
      color_code: "#00000099",
    },
    {
      title: "Architecting with Google Compute Engine",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/7WKXQB3B57TH",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "React for Fronted Developers",
      subtitle: "- Educative",
      logo_path: "educative_inc_logo.jpeg",
      certificate_link:
        "https://www.educative.io/verify-certificate/y8E3zVtAgNzA70AnquxXxQq2kPWyTm",
      alt_name: "Educative",
      color_code: "#1F70C199",
    },
    {
      title: "Java (Basic) Certificate",
      subtitle: "- Hacker Rank",
      logo_path: "hackerrank_logo.jpeg",
      certificate_link:
        "https://www.hackerrank.com/certificates/a4f8eb309654",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Cloud Study Jam-Kubernetes Engine",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/7a718219-293c-4330-8a84-a1612d7312b1",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "GCP Essentials",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://www.cloudskillsboost.google/public_profiles/7a718219-293c-4330-8a84-a1612d7312b1",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with startup and NGOs as Application and Web Developer, Designer and Software Architect. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Associate Technical Lead - Java",
          company: "BluePi Consulting Pvt Ltd",
          company_url: "https://bluepiit.com/",
          logo_path: "bluepilogo.png",
          duration: "January 2024 - Present",
          location: "Gurugram, Haryana, India",
          description:
            "Spearheaded legacy application migration from Oracle and on-prem servers to Cloud. Experience working on microservice based architectures. Designed & Developed an ecosystem for monitoring post-trade activities reducing resolution time by 30% by generating alerts in real time for any impact in routes of trading. Currently leading a team of 3 members.",
          color: "#000000",
        },
        {
          title: "Senior Engineer - Application",
          company: "BluePi Consulting Pvt Ltd",
          company_url: "https://bluepiit.com/",
          logo_path: "bluepilogo.png",
          duration: "January 2023 - January 2024",
          location: "Gurugram, Haryana, India",
          description:
            "Led the development of a real-time dashboard for India's largest Foodservice Company by leveraging microservice based architure and AWS services. Delivered key KPIs, average delivery time, and performance of sales in real-time, enhancing customer’s experience and business decisions. Designed and implemented scalable information post feed solution on GCP with Spring and Spring Boot for an initiative by Government of India for Mobile Privacy and Security Issues of Indian citizens.",
          color: "#0879bf",
        },
        {
          title: "Application Development Engineer",
          company: "BluePi Consulting Pvt Ltd",
          company_url: "https://bluepiit.com/",
          logo_path: "bluepilogo.png",
          duration: "November 2021 - January 2023",
          location: "Gurugram, Haryana, India",
          description:
            "Led the development of the CDP + CMT SaaS, delivering real-time personalized interventions and effective campaign management. Developed and integrated a pub/sub model to send campaign notifications via WhatsApp using a third-party service API. Implemented a dynamic environment capable of ingesting clickstream data in any format and delivering dynamic interventions. Utilized AWS services such as EC2, EMR, Kinesis, RDS, Redis, SNS, SQS, Cloudfront, and S3 to build and deploy the product.",
          color: "#9b1578",
        }
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Product Development Intern",
          company: "Intellimation.ai",
          company_url: "https://www.intellimation.ai/",
          logo_path: "intellimation-logo.png",
          duration: "November 2020 - May 2021",
          location: "Work From Home (Covid Years)",
          description:
            "Implemented Regex based text parsing module and reduced input process running time of the product by 40%. Designed and implemented image processing features of the reconciliation product using OpenCV using both Java and Python. Delivered an end to end reconciliation project developed using SVN, Java & Tomcat receiving an accuracy greater than 90%.",
          color: "#000000",
          height: "68px"
        },
        {
          title: "Web Development Intern",
          company: "Symbiosis Institute of Geoinformatics",
          company_url:
            "https://sig.ac.in/",
          logo_path: "siu2.jpeg",
          duration: "Nov 2018 - March 2019",
          location: "Pune, Maharastra, India",
          description:
            "Designed and Developed a website with the team for the Drought Moitoring System for the models presented as a part of research of Symbiosis Institute of Geoinformatics. We were provided financial assistance for the project by Department of Science and Technology (DST), India.",
          color: "#0071C5",
        },
        {
          title: "Web Development Intern",
          company: "Tara Mobile Creches (NGO)",
          company_url: "https://taramobilecreches.org/",
          logo_path: "logowhite.jpg",
          duration: "May 2018 - Sept 2018",
          location: "Pune, Maharastra, India",
          description:
            "Designed and Developed an automated attendance system for the NGO, which would help them to manage the attendance and genenrated monthly hour reports as well. I have closely worked with QR Code based implemetation in combination with location tracking to create solution for this. At the end of internship, we had created a web app and deployed on Tomcat server.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Tech Team Lead",
          company: "ACM SICSR",
          company_url: "https://sicsr.acm.org/",
          logo_path: "acm_logo.png",
          duration: "July 2019 - March 2020",
          location: "Pune, Maharastra, India",
          description:
            "ACM is an International learned society for computing founded in 1947 and is the world’s largest scientific and educational society. ACM Student Chapter is the International student’s society that provide unique opportunities for networking, mentoring and bonding over common interests. As a tech team lead my responsibilties were to help promote our branch and design tech solutions for the chapter.",
          color: "#D83B01",
        },
        {
          title: "IEEE Student Chair",
          company: "SICSR",
          company_url: "https://edu.ieee.org/in-sicsr/",
          logo_path: "cropped-cropped-SICSR-Student-Branch-3.webp",
          duration: "December 2017 - Feb 2019",
          location: "Pune, Maharastra, India",
          description:
            "IEEE SICSR Pune Student Branch was initiated in the year 2017 under the able guidance of the then Deputy Director, Mr. Harshad Gune and our Branch Counsellor Dr. Rajashree Jain.  Thus, began an enriching and exciting journey of our Student Branch. It was inaugurated offically on 19th January 2019. Since, then our student branch has been conducting several events, activities, workshops, hackathons and membership drive sessions with each passing year. It won the Best Emerging Student Branch of the year 2019, India & Best Student Branch Pune Section 2019.",
          color: "#4285F4",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC SICSR, Pune",
          company_url:
            "https://www.instagram.com/dscsicsr/",
          logo_path: "dsc_logo.png",
          duration: "July 2017 - Nov 2017",
          location: "Pune, Maharastra, India",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter.",
          color: "#0C9D58",
        },
        {
          title: "Internshala Student Partner",
          company: "Internshala",
          company_url: "https://isp.internshala.com/",
          logo_path: "isp_logo.svg",
          duration: "July 2017 - September 2017",
          location: "Pune, Maharastra, India",
          description:
            "It was 60-day transformational journey to help our college students meet their career goals and learn real-world skills like marketing, communication & leadership. My responsibility for this program was to promote and engage more students towards intershala and help them get their first internship in college and in the city.",
          color: "#000000",
          height: "68px"
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create real time dashboards & web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "ACM Student Chapter SICSR",
      createdAt: "2019-12-02T00:00:00Z",
      description: "The Dreams Which Got Wings!",
      url: "https://medium.com/acm-sicsr/the-dreams-which-got-wings-acm-student-chapter-sicsr-4aa2e881b022",
    },
    {
      id: "mdp-diffusion",
      name: "Cancer — End of a story",
      createdAt: "2020-07-19T00:00:00Z",
      description: "No Shave November Support",
      url: "https://medium.com/@ashutoshkumarbook/cancer-end-of-a-story-2510f6a87238",
    }
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    description:
      "Discuss a project or just want to say hi? My Inbox is open for all. I can help you with Spring Boot, Java, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to write and share knowledge.",
    link: "https://medium.com/@ashutoshkumarbook",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Rosewood City, Gurugram",
    locality: "Gurugram",
    country: "India",
    region: "Haryana",
    postalCode: "122018",
    streetAddress: "Rosewood City",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://www.google.com/maps/place/Rosewood+City,+Ghasola,+Sector+49,+Gurugram,+Haryana+122018/@28.4079817,77.0454046,16z/data=!3m1!4b1!4m6!3m5!1s0x390d22883b4dbfdf:0xbfb1d9f5d8e66be!8m2!3d28.4084534!4d77.0550072!16s%2Fg%2F11r8twm31?entry=ttu&g_ep=EgoyMDI0MDgyNy4wIKXMDSoASAFQAw%3D%3D",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
